@import "tailwindcss/components";

@layer components {
  .flex-center {
    @apply flex justify-center items-center;
  }

  .pms-drawer-border-color {
    @apply border-zinc-200 border-solid;
  }
  .pms-drawer-title {
    @apply bg-zinc-50 p-2 border-0 pms-drawer-border-color flex items-center;
  }
  .pms-drawer-value {
    @apply p-2 flex items-center;
  }

  .pms-btn-blue {
    @apply [&:not(:disabled)]:text-white 
    [&:not(:disabled)]:hover:text-white
    [&:not(:disabled)]:bg-[#8ca0f0] 
    [&:not(:disabled)]:border-[#8ca0f0] 
    [&:not(:disabled)]:hover:bg-[#7891F2]
    [&:not(:disabled)]:hover:border-[#7891F2];
  }
  .pms-btn-blue-outline {
    @apply [&:not(:disabled)]:text-[#7891F2]
    [&:not(:disabled)]:hover:text-white
    [&:not(:disabled)]:border-[#7891F2] 
    [&:not(:disabled)]:bg-white
    [&:not(:disabled)]:hover:bg-[#7891F2]
    [&:not(:disabled)]:hover:border-[#7891F2];
  }
  .oms-btn-blue-outline {
    @apply [&:not(:disabled)]:text-[#4B6EA2]
    [&:not(:disabled)]:hover:text-white
    [&:not(:disabled)]:border-[#4B6EA2] 
    [&:not(:disabled)]:bg-white
    [&:not(:disabled)]:hover:bg-[#4B6EA2]
    [&:not(:disabled)]:hover:border-[#4B6EA2];
  }
  .pms-btn-purple {
    @apply [&:not(:disabled)]:text-white 
    [&:not(:disabled)]:hover:text-white
    [&:not(:disabled)]:bg-[#6A68A1] 
    [&:not(:disabled)]:hover:bg-[#7b7ba9]
    [&:not(:disabled)]:hover:border-[#7b7ba9];
  }
  .oms-btn-darkBlue {
    @apply [&:not(:disabled)]:text-white 
    [&:not(:disabled)]:hover:text-white
    [&:not(:disabled)]:bg-[#4B6EA2] 
    [&:not(:disabled)]:border-[#4B6EA2] 
    [&:not(:disabled)]:hover:bg-[#5e779e]
    [&:not(:disabled)]:hover:border-[#5e779e];
  }
  .pms-btn-orange {
    @apply [&:not(:disabled)]:text-white 
    [&:not(:disabled)]:hover:text-white
    [&:not(:disabled)]:bg-[#FC5F2E] 
    [&:not(:disabled)]:hover:bg-[#fa754d]
    [&:not(:disabled)]:hover:border-[#fa754d];
  }
  .pms-btn-red {
    @apply [&:not(:disabled)]:text-white 
    [&:not(:disabled)]:hover:text-white
    [&:not(:disabled)]:bg-[#F23924] 
    [&:not(:disabled)]:hover:bg-[#f05342]
    [&:not(:disabled)]:hover:border-[#f05342];
  }
}
